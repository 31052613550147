import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Clipboard = ({ text }) => {
  const [copy, setCopy] = useState("Copy");

  return (
    <Tooltip title={copy} placement="top">
      <CopyToClipboard
        text={`${text}`}
        onCopy={() => setCopy("Copied!")}
        onMouseEnter={() => setCopy("Copy")}
      >
        <div>{text}</div>
      </CopyToClipboard>
    </Tooltip>
  );
};

export default Clipboard;
