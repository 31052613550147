import Grid from "@material-ui/core/Grid";
import React from "react";
import CopyToClipboard from "../../components/CopyToClipboard";
import "./TwoColumnContactInfo.scss";
import RevealAnimation from "../../components/RevealAnimation";

const TwoColumnContactInfo = (props) => {
  return (
    <Grid className="contactinfo-main" container>
      <Grid item sm={6} xs={12} md={6}>
        <Grid direction="row" container className="contactinfo-clm1">
          <Grid>
            <RevealAnimation
              component={"img"}
              src={props.TwoColumnContactInfoData.Clm1Img}
              alt="Digiryte Phone"
              className="device-style"
            />
          </Grid>
          <Grid className="contactinfo-clm-info-wapper">
            <RevealAnimation className="contactinfo-clm-title">
              {props.TwoColumnContactInfoData.Clm1Title}
            </RevealAnimation>
            <RevealAnimation className="contactinfo-clm-subtitle">
              <CopyToClipboard
                text={`${props.TwoColumnContactInfoData.Clm1SubTitle}`}
              />
            </RevealAnimation>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12} md={6}>
        <Grid
          direction="row"
          container
          className="contactinfo-clm2 device-wrapper"
        >
          <Grid>
            <RevealAnimation
              component={"img"}
              src={props.TwoColumnContactInfoData.Clm2Img}
              alt="Digiryte Phone"
              className="device-style"
            />
          </Grid>
          <Grid className="contactinfo-clm-info-wapper">
            <RevealAnimation className="contactinfo-clm-title">
              {props.TwoColumnContactInfoData.Clm2Title}
            </RevealAnimation>
            <RevealAnimation className="contactinfo-clm-subtitle">
              <a
                href={`mailto:${props.TwoColumnContactInfoData.Clm2SubTitle}`}
                style={{ color: "white", textDecoration: "none" }}
              >
                {props.TwoColumnContactInfoData.Clm2SubTitle}
              </a>
            </RevealAnimation>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TwoColumnContactInfo;
